<template>
  <v-container fluid>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                AdHoc PF Entity Report
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row>
                    <v-col sm="12" md="2">
                      <BaseDatePickerWithText
                        label="Start Date"
                        clearable
                        hint="Based on Date Entered"
                        v-model="startDate"
                      ></BaseDatePickerWithText>
                    </v-col>
                    <v-col sm="12" md="2">
                      <BaseDatePickerWithText
                        label="End Date"
                        hint="Based on Date Entered"
                        clearable
                        v-model="endDate"
                      ></BaseDatePickerWithText>
                    </v-col>

                    <v-col sm="12" md="2">
                      <v-text-field
                        v-model="searchText"
                        prepend-inner-icon="mdi-text-box-search"
                        label="Search"
                        hint="FEIN, FullName, ShortName, Phone, Extension, Fax, Website, EntityStatus, TaxNum, TaxNumDesc, CensusNumber, EntityAddress, EntityCity, EntityState, EntityZip, EntityPhysicalAddress, EntityPhysicalCity, EntityPhysicalState, EntityPhysicalZip, EntityType, CogEntityType, County, CensusReport, CensusReportDescription, "
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="3" class="mt-8">
                      <v-autocomplete
                        v-model="selectedFields"
                        :items="headers"
                        item-text="text"
                        item-value="value"
                        label="Select Columns"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      >
                        <template v-slot:selection="{item, index}">
                          <v-chip
                            small
                            v-if="index < 5"
                            close
                            @click:close="selectedFields.splice(index, 1)"
                            >{{ item.text }}</v-chip
                          >
                          <span v-if="index === 5" class="grey--text caption">
                            (+{{ selectedFields.length - 5 }}
                            others)
                          </span>
                        </template>
                        <template v-slot:prepend>
                          <reorderSelectedFields
                            v-model="selectedFields"
                          ></reorderSelectedFields>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col sm="12" md="2">
                      <h4
                        @click="toggleFilters"
                        class="pointer primary--text"
                        >{{ filterText }}</h4
                      >
                    </v-col>
                  </v-row>
                  <v-row v-if="filters">
                    <v-col sm="12" md="3" class="mt-4">
                      <v-select
                        v-model="entityStatus"
                        :items="statusList"
                        label="Entity Status"
                        chips
                        deletable-chips
                        small-chips
                        multiple
                        clearable
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="3">
                      <LuPFEntityType
                        v-model="entityTypeID"
                        label="Entity Type"
                      ></LuPFEntityType>
                    </v-col>
                    <v-col sm="12" md="3">
                      <LuCensusReport
                        v-model="censusReportID"
                        label="Census Report"
                      ></LuCensusReport>
                    </v-col>
                    <v-col sm="12" md="3">
                      <LuCounty v-model="countyID" label="County"></LuCounty>
                    </v-col>
                    <v-col sm="12" md="3">
                      <LuCOGEntityType
                        v-model="cOGEntityTypeID"
                        label="COG Entity Type"
                      ></LuCOGEntityType>
                    </v-col>
                    <!-- The Description and ID (item-text and item-value) 
                                        will need to be adjusted based on the properties in the array/lookup table
                                        these availableLists should be pulled from lookup tables and potentially
                                        filtered with a computed as desired (for IsActive status etc)
                      These can also be switched to v-autocompletes if desired-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="entityID" 
                                                :items="availableEntityID"
                                                item-text="Description" 
                                                item-value="ID" 
                                                label="Entity ID"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
                    <!--
                                        <EntityID
                                            v-model="entityID"
                                            small-chips
                                            dense
                                        ></EntityID>
                      -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="fEIN" 
                                                :items="availableFEIN"
                                                label="F E I N"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="fullName" 
                                                :items="availableFullName"
                                                label="Full Name"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="shortName" 
                                                :items="availableShortName"
                                                label="Short Name"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="phone" 
                                                :items="availablePhone"
                                                label="Phone"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="extension" 
                                                :items="availableExtension"
                                                label="Extension"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="fax" 
                                                :items="availableFax"
                                                label="Fax"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="website" 
                                                :items="availableWebsite"
                                                label="Website"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="addressId" 
                                                :items="availableAddressId"
                                                item-text="Description" 
                                                item-value="ID" 
                                                label="Address Id"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
                    <!--
                                        <AddressId
                                            v-model="addressId"
                                            small-chips
                                            dense
                                        ></AddressId>
                      -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="entityStatus" 
                                                :items="availableEntityStatus"
                                                label="Entity Status"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="taxNum" 
                                                :items="availableTaxNum"
                                                label="Tax Num"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="taxNumDesc" 
                                                :items="availableTaxNumDesc"
                                                label="Tax Num Desc"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="pAddressID" 
                                                :items="availablePAddressID"
                                                item-text="Description" 
                                                item-value="ID" 
                                                label="P Address ID"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
                    <!--
                                        <PAddressID
                                            v-model="pAddressID"
                                            small-chips
                                            dense
                                        ></PAddressID>
                      -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="publicFundsEntityID" 
                                                :items="availablePublicFundsEntityID"
                                                item-text="Description" 
                                                item-value="ID" 
                                                label="Public Funds Entity ID"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
                    <!--
                                        <PublicFundsEntityID
                                            v-model="publicFundsEntityID"
                                            small-chips
                                            dense
                                        ></PublicFundsEntityID>
                      -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="entityTypeID" 
                                                :items="availableEntityTypeID"
                                                item-text="Description" 
                                                item-value="ID" 
                                                label="Entity Type ID"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
                    <!--
                                        <EntityTypeID
                                            v-model="entityTypeID"
                                            small-chips
                                            dense
                                        ></EntityTypeID>
                      -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="fiscalMonthYearEnd" 
                                                :items="availableFiscalMonthYearEnd"
                                                item-text="Description" 
                                                item-value="ID" 
                                                label="Fiscal Month Year End"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
                    <!--
                                        <FiscalMonthYearEnd
                                            v-model="fiscalMonthYearEnd"
                                            small-chips
                                            dense
                                        ></FiscalMonthYearEnd>
                      -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="censusReportID" 
                                                :items="availableCensusReportID"
                                                item-text="Description" 
                                                item-value="ID" 
                                                label="Census Report ID"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
                    <!--
                                        <CensusReportID
                                            v-model="censusReportID"
                                            small-chips
                                            dense
                                        ></CensusReportID>
                      -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="countyID" 
                                                :items="availableCountyID"
                                                item-text="Description" 
                                                item-value="ID" 
                                                label="County ID"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
                    <!--
                                        <CountyID
                                            v-model="countyID"
                                            small-chips
                                            dense
                                        ></CountyID>
                      -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="censusNumber" 
                                                :items="availableCensusNumber"
                                                label="Census Number"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="entityAddress" 
                                                :items="availableEntityAddress"
                                                label="Entity Address"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="entityCity" 
                                                :items="availableEntityCity"
                                                label="Entity City"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="entityState" 
                                                :items="availableEntityState"
                                                label="Entity State"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="entityZip" 
                                                :items="availableEntityZip"
                                                label="Entity Zip"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="entityPhysicalAddress" 
                                                :items="availableEntityPhysicalAddress"
                                                label="Entity Physical Address"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="entityPhysicalCity" 
                                                :items="availableEntityPhysicalCity"
                                                label="Entity Physical City"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="entityPhysicalState" 
                                                :items="availableEntityPhysicalState"
                                                label="Entity Physical State"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="entityPhysicalZip" 
                                                :items="availableEntityPhysicalZip"
                                                label="Entity Physical Zip"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="entityType" 
                                                :items="availableEntityType"
                                                label="Entity Type"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="cOGEntityTypeID" 
                                                :items="availableCOGEntityTypeID"
                                                item-text="Description" 
                                                item-value="ID" 
                                                label="C O G Entity Type ID"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
                    <!--
                                        <COGEntityTypeID
                                            v-model="cOGEntityTypeID"
                                            small-chips
                                            dense
                                        ></COGEntityTypeID>
                      -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="cogEntityType" 
                                                :items="availableCogEntityType"
                                                label="Cog Entity Type"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="county" 
                                                :items="availableCounty"
                                                label="County"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="countyPopulation" 
                                                :items="availableCountyPopulation"
                                                item-text="Description" 
                                                item-value="ID" 
                                                label="County Population"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
                    <!--
                                        <CountyPopulation
                                            v-model="countyPopulation"
                                            small-chips
                                            dense
                                        ></CountyPopulation>
                      -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="censusReport" 
                                                :items="availableCensusReport"
                                                label="Census Report"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="censusReportDescription" 
                                                :items="availableCensusReportDescription"
                                                label="Census Report Description"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                      </v-col>-->
                  </v-row>
                  <pivotOptions
                    :pivot="pivot"
                    :filteredHeaders="filteredHeaders"
                  ></pivotOptions>
                </v-card-text>
                <template>
                  <v-data-table
                    :headers="filteredHeaders"
                    :items="entries"
                    :server-items-length="total"
                    :loading="loading"
                    :sort-by.sync="pagination.sortBy"
                    :sort-desc.sync="pagination.descending"
                    :page.sync="pagination.page"
                    :items-per-page.sync="pagination.rowsPerPage"
                    :footer-props="{itemsPerPageOptions: [5, 10, 25, 50]}"
                    class="pt-4"
                    dense
                  ></v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col sm="auto">
        <v-btn @click="getAdhoc" color="primary">
          <v-icon left>mdi-file-chart</v-icon>Generate AdHoc
        </v-btn>
      </v-col>
    </v-row>
    <SavedQueries
      reportName="PFEntity"
      :searchParams="searchParams"
      @selectQuery="selectQuery"
    ></SavedQueries>
  </v-container>
</template>

<script>
import pivotOptions from '@components/reports/pivotOptionsObject'
import reorderSelectedFields from '@components/reports/reorderSelectedFields'
import SavedQueries from '@components/reports/savedQueries'
import download from 'downloadjs'
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import {debounce, range} from 'lodash'
import LuCensusReport from '@components/select/a-multi-id/LuCensusReport'
import LuCOGEntityType from '@components/select/a-multi-id/LuCOGEntityType'
import LuCounty from '@components/select/a-multi-id/LuCounty'
import LuPFEntityType from '@components/select/a-multi-id/LuPFEntityType'
//templateTODO: select default sortBy field
var defaultSearchField = 'EntityID'

export default {
  name: 'PFEntityReports',
  page: {
    title: 'PF Entity Reports',
  },
  components: {
    pivotOptions,
    reorderSelectedFields,
    LuCensusReport,
    LuCOGEntityType,
    LuCounty,
    LuPFEntityType,
    SavedQueries,
  },
  data: () => ({
    valid: true,
    panel: [0, 1],
    searchText: '',
    entries: [],
    startDate: null,
    endDate: null,
    //templateTODO: uncomment as used in AdHocVwPFEntityReportParams
    // entityID: [],
    // fEIN: [],
    // fullName: [],
    // shortName: [],
    // phone: [],
    // extension: [],
    // fax: [],
    // website: [],
    // addressId: [],
    entityStatus: ['Active'],
    statusList: ['Active', 'Inactive'],
    // taxNum: [],
    // taxNumDesc: [],
    // pAddressID: [],
    // publicFundsEntityID: [],
    entityTypeID: [],
    // fiscalMonthYearEnd: [],
    censusReportID: [],
    countyID: [],
    // censusNumber: [],
    // entityAddress: [],
    // entityCity: [],
    // entityState: [],
    // entityZip: [],
    // entityPhysicalAddress: [],
    // entityPhysicalCity: [],
    // entityPhysicalState: [],
    // entityPhysicalZip: [],
    // entityType: [],
    cOGEntityTypeID: [],
    // cogEntityType: [],
    // county: [],
    // countyPopulation: [],
    // censusReport: [],
    // censusReportDescription: [],
    pivot: {
      includePivot: false,
      pivotFirst: true,
      pivotName: '',
      rowFields: [],
      columnFields: [],
      excludeFields: [],
      dataFields: [],
      filterFields: [],
    },
    filters: true,
    filterText: '-Hide Filters-',
    total: 0,
    searchCount: 0,

    pagination: {
      page: 1,
      sortBy: defaultSearchField,
      descending: false,
      rowsPerPage: 25,
    },
    selectedFields: [],
    headers: [
      {
        text: 'Entity ID',
        align: 'center',
        sortable: true,
        value: 'EntityID',
        visible: true,
      },
      {
        text: 'FEIN',
        align: 'center',
        sortable: true,
        value: 'FEIN',
        visible: false,
      },
      {
        text: 'Full Name',
        align: 'center',
        sortable: true,
        value: 'FullName',
        visible: true,
      },
      {
        text: 'Short Name',
        align: 'center',
        sortable: true,
        value: 'ShortName',
        visible: true,
      },
      {
        text: 'Phone',
        align: 'center',
        sortable: true,
        value: 'Phone',
        visible: true,
      },
      {
        text: 'Extension',
        align: 'center',
        sortable: true,
        value: 'Extension',
        visible: true,
      },
      {
        text: 'Fax',
        align: 'center',
        sortable: true,
        value: 'Fax',
        visible: true,
      },
      {
        text: 'Website',
        align: 'center',
        sortable: true,
        value: 'Website',
        visible: true,
      },
      {
        text: 'Address Id',
        align: 'center',
        sortable: true,
        value: 'AddressId',
        visible: false,
      },
      {
        text: 'Entity Status',
        align: 'center',
        sortable: true,
        value: 'EntityStatus',
        visible: true,
      },
      {
        text: 'Tax Num',
        align: 'center',
        sortable: true,
        value: 'TaxNum',
        visible: false,
      },
      {
        text: 'Tax Num Desc',
        align: 'center',
        sortable: true,
        value: 'TaxNumDesc',
        visible: false,
      },
      {
        text: 'P Address ID',
        align: 'center',
        sortable: true,
        value: 'PAddressID',
        visible: false,
      },
      {
        text: 'Public Funds Entity ID',
        align: 'center',
        sortable: true,
        value: 'PublicFundsEntityID',
        visible: false,
      },
      {
        text: 'Date Entered',
        align: 'center',
        sortable: true,
        value: 'DateEntered',
        visible: true,
      },
      {
        text: 'Entity Type ID',
        align: 'center',
        sortable: true,
        value: 'EntityTypeID',
        visible: false,
      },
      {
        text: 'Fiscal Month Year End',
        align: 'center',
        sortable: true,
        value: 'FiscalMonthYearEnd',
        visible: true,
      },
      {
        text: 'Origination Date',
        align: 'center',
        sortable: true,
        value: 'OriginationDate',
        visible: true,
      },
      {
        text: 'Census Report ID',
        align: 'center',
        sortable: true,
        value: 'CensusReportID',
        visible: false,
      },
      {
        text: 'County ID',
        align: 'center',
        sortable: true,
        value: 'CountyID',
        visible: false,
      },
      {
        text: 'Census Number',
        align: 'center',
        sortable: true,
        value: 'CensusNumber',
        visible: true,
      },
      {
        text: 'Dissolved Date',
        align: 'center',
        sortable: true,
        value: 'DissolvedDate',
        visible: true,
      },
      {
        text: 'Entity Address',
        align: 'center',
        sortable: true,
        value: 'EntityAddress',
        visible: true,
      },
      {
        text: 'Entity City',
        align: 'center',
        sortable: true,
        value: 'EntityCity',
        visible: true,
      },
      {
        text: 'Entity State',
        align: 'center',
        sortable: true,
        value: 'EntityState',
        visible: true,
      },
      {
        text: 'Entity Zip',
        align: 'center',
        sortable: true,
        value: 'EntityZip',
        visible: true,
      },
      {
        text: 'Entity Physical Address',
        align: 'center',
        sortable: true,
        value: 'EntityPhysicalAddress',
        visible: true,
      },
      {
        text: 'Entity Physical City',
        align: 'center',
        sortable: true,
        value: 'EntityPhysicalCity',
        visible: true,
      },
      {
        text: 'Entity Physical State',
        align: 'center',
        sortable: true,
        value: 'EntityPhysicalState',
        visible: true,
      },
      {
        text: 'Entity Physical Zip',
        align: 'center',
        sortable: true,
        value: 'EntityPhysicalZip',
        visible: true,
      },
      {
        text: 'Entity Type',
        align: 'center',
        sortable: true,
        value: 'EntityType',
        visible: true,
      },
      {
        text: 'COG Entity Type ID',
        align: 'center',
        sortable: true,
        value: 'COGEntityTypeID',
        visible: true,
      },
      {
        text: 'Cog Entity Type',
        align: 'center',
        sortable: true,
        value: 'CogEntityType',
        visible: true,
      },
      {
        text: 'County',
        align: 'center',
        sortable: true,
        value: 'County',
        visible: true,
      },
      {
        text: 'County Population',
        align: 'center',
        sortable: true,
        value: 'CountyPopulation',
        visible: true,
      },
      {
        text: 'Census Report',
        align: 'center',
        sortable: true,
        value: 'CensusReport',
        visible: true,
      },
      {
        text: 'Census Report Description',
        align: 'center',
        sortable: true,
        value: 'CensusReportDescription',
        visible: true,
      },
    ],
  }),
  created() {
    this.selectedFields = this.headers
      .filter((h) => h.visible)
      .map((h) => h.value)
    this.bounce(this)
  },
  watch: {
    pagination: {
      handler() {
        this.bounce(this)
      },
      deep: true,
    },
    selectedFields: {
      handler(nval, oval) {
        this.headers.forEach((item) => {
          if (nval.includes(item.value)) {
            item.visible = true
          } else {
            item.visible = false
          }
        })
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
      deep: true,
    },

    //for each search parameter we need to call bounce to search once the user has made changes
    searchText: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    startDate: {
      handler() {
        this.bounce(this)
      },
    },
    endDate: {
      handler() {
        this.bounce(this)
      },
    },
    ///templateTODO: uncomment as used in AdHocVwPFEntityReportParams
    //  entityID: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  fEIN: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  fullName: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  shortName: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  phone: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  extension: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  fax: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  website: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  addressId: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    entityStatus: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    //  taxNum: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  taxNumDesc: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  pAddressID: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  publicFundsEntityID: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    entityTypeID: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    //  fiscalMonthYearEnd: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    censusReportID: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    countyID: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    //  censusNumber: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  entityAddress: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  entityCity: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  entityState: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  entityZip: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  entityPhysicalAddress: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  entityPhysicalCity: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  entityPhysicalState: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  entityPhysicalZip: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  entityType: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    cOGEntityTypeID: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    //  cogEntityType: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  county: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  countyPopulation: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  censusReport: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  censusReportDescription: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
  },
  computed: {
    ...authComputed,
    loading() {
      return this.searchCount > 0
    },
    //this can be used for filtering them down
    filteredHeaders: {
      get() {
        let list = this.headers.filter((h) => h.visible)
        list.sort((a, b) => {
          return (
            this.selectedFields.indexOf(a.value) -
            this.selectedFields.indexOf(b.value)
          )
        })
        return list
      },
    },
    searchParams() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        sortProperty: this.pagination.sortBy,
        sortDirection: this.pagination.descending ? 'desc' : 'asc',
        searchText: this.searchText,
        selectedCategories: this.selectedCategories,
        includeFields: this.selectedFields,
        queryName: this.queryName,
        queryIsModified: this.queryIsModified,
        pivotOptions: this.pivot,
        //templateTODO: uncomment as used in AdHocVwPFEntityReportParams
        // entityID: this.entityID,
        // fEIN: this.fEIN,
        // fullName: this.fullName,
        // shortName: this.shortName,
        // phone: this.phone,
        // extension: this.extension,
        // fax: this.fax,
        // website: this.website,
        // addressId: this.addressId,
        entityStatus: this.entityStatus,
        // taxNum: this.taxNum,
        // taxNumDesc: this.taxNumDesc,
        // pAddressID: this.pAddressID,
        // publicFundsEntityID: this.publicFundsEntityID,
        entityTypeID: this.entityTypeID,
        // fiscalMonthYearEnd: this.fiscalMonthYearEnd,
        censusReportID: this.censusReportID,
        countyID: this.countyID,
        // censusNumber: this.censusNumber,
        // entityAddress: this.entityAddress,
        // entityCity: this.entityCity,
        // entityState: this.entityState,
        // entityZip: this.entityZip,
        // entityPhysicalAddress: this.entityPhysicalAddress,
        // entityPhysicalCity: this.entityPhysicalCity,
        // entityPhysicalState: this.entityPhysicalState,
        // entityPhysicalZip: this.entityPhysicalZip,
        // entityType: this.entityType,
        cOGEntityTypeID: this.cOGEntityTypeID,
        // cogEntityType: this.cogEntityType,
        // county: this.county,
        // countyPopulation: this.countyPopulation,
        // censusReport: this.censusReport,
        // censusReportDescription: this.censusReportDescription,
      }
    },
    //templateTODO: available lookup computeds should go here
  },
  methods: {
    handleError: call('errors/handleError'),
    toggleFilters() {
      this.filters = !this.filters
      if (this.filters) {
        this.filterText = '-Hide Filters-'
      } else {
        this.filterText = '-Show Filters-'
      }
    },
    searchReports() {
      if (!this.pagination.sortBy) {
        this.pagination.sortBy = defaultSearchField
        this.pagination.descending = false
      }
      if (Array.isArray(this.pagination.sortBy)) {
        if (this.pagination.sortBy.length > 0) {
          this.pagination.sortBy = this.pagination.sortBy[0]
        } else {
          this.pagination.sortBy = defaultSearchField
        }
        this.pagination.descending = false
      }
      if (!this.searchText) {
        this.searchText = ''
      }
      this.searchCount++
      const url = `AdHocPFEntityReport/Display?pageNum=${
        this.pagination.page - 1
      }&pageSize=${this.pagination.rowsPerPage}`
      this.$axios.post(url, this.searchParams).then(
        (res) => {
          this.pagination.rowsPerPage = res.data.Page.Size
          this.pagination.page = res.data.Page.Number + 1
          this.total = res.data.Page.TotalElements
          this.entries = res.data.Entries
          this.searchCount--
        },
        (error) => {
          this.handleError(error)
          console.error(error)
          this.searchCount--
        }
      )
    },
    getAdhoc(type) {
      let headers = {responseType: 'blob'}
      let url = 'AdHocPFEntityReport'
      this.$axios.post(url, this.searchParams, headers).then(
        (res) => {
          const content = res.headers['content-type']
          const dispo = res.headers['content-disposition']
          var filename = ''
          if (dispo && dispo.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            var matches = filenameRegex.exec(dispo)
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '')
            }
          }
          download(res.data, filename, content)
        },
        (error) => {
          this.handleError(error)
          console.error(error)
        }
      )
    },

    selectQuery(item) {
      if (item && item.Query) {
        let query = JSON.parse(item.Query)
        this.pagination.descending =
          query.sortDirection == 'desc' ? true : false
        this.pagination.sortBy = query.sortProperty || defaultSearchField
        this.searchText = query.searchText || ''
        this.queryName = query.queryName || item.Title
        this.selectedFields = query.includeFields || []
        if (!query.pivotOptions) {
          this.adhocPivotIncludePivot = query.includePivot
          this.adhocPivotFirst = query.pivotFirst
          this.adhocPivotName = query.pivotName
          this.adhocPivotRowFields = query.rowFields || []
          this.adhocPivotColumnFields = query.columnFields || []
          this.adhocPivotExcludeFields = query.excludeFields || []
          this.adhocPivotDataFields = query.dataFields || []
          this.adhocPivotFilterFields = query.filterFields || []
        } else {
          this.adhocPivotIncludePivot = query.pivotOptions.includePivot
          this.adhocPivotFirst = query.pivotOptions.pivotFirst
          this.adhocPivotName = query.pivotOptions.pivotName
          this.adhocPivotRowFields = query.pivotOptions.rowFields || []
          this.adhocPivotColumnFields = query.pivotOptions.columnFields || []
          this.adhocPivotExcludeFields = query.pivotOptions.excludeFields || []
          this.adhocPivotDataFields = query.pivotOptions.dataFields || []
          this.adhocPivotFilterFields = query.pivotOptions.filterFields || []
        }

        this.startDate = query.startDate
        this.endDate = query.endDate
        this.entityStatus = query.entityStatus
        this.entityTypeID = query.entityTypeID
        this.censusReportID = query.censusReportID
        this.countyID = query.countyID
        this.cOGEntityTypeID = query.cOGEntityTypeID
      } else {
        this.resetQuery()
      }

      if (item && item.ID <= 0) {
        //this can be used for additional processing for query presets that are hardcoded in javascript,
      }
      this.$nextTick(() => {
        this.queryIsModified = false
      })
    },
    resetQuery() {
      this.queryName = ''
      this.queryIsModified = false
    },

    bounce: _.debounce((self) => {
      self.searchReports()
    }, 2000),
  },
}
</script>

<style lang="scss">
//@import '@design';
</style>
